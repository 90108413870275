import axios from "axios";
import authHeader from "./auth-header";

const API_URL = "https://stagingtestonline.soechigroup.com/api/exam/";
// const API_URL = "http://10.1.109.236:8000/api/exam/";

const getBankSoal = () => {
    return axios.get(API_URL + "banksoal", { headers: authHeader() });
};

const detailBankSoal = (id) => {
    return axios.get(API_URL + "banksoal/" + id, { headers: authHeader() });
}

const detailModuleSoal = (id) => {
    return axios.get(API_URL + "modulsoal/" + id, { headers: authHeader() });
}

const getQuestions = (id) => {
    return axios.get(API_URL + "questions/" + id, { headers: authHeader() });
}

const saveCandidateAnswer = (id, data) => {
    return axios.post(API_URL + "candidate-answer/" + id, data, { headers: authHeader() });
}

const saveTempCandidateAnswer = (id, data) => {
    return axios.post(API_URL + "temp-candidate-answer/" + id, data, { headers: authHeader() });
}

export default {
    getBankSoal,
    detailBankSoal,
    detailModuleSoal,
    getQuestions,
    saveCandidateAnswer,
    saveTempCandidateAnswer
};