import React, { useEffect, useRef, useState } from "react";
import './styles.css';
import { Navigate, useNavigate, useParams } from "react-router-dom";
import ReactHtmlParser from 'html-react-parser';

import ExamService from "services/exam.service";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "redux/actions/auth";
import moment from "moment/moment";
import { Loading } from "components";
import { useOnPrintScreen } from "context/useOnKeyPress";

const Question = () => {

    const params = useParams();
    const [title, setTitle] = useState("");
    const [banksoaltype, setBankSoalType] = useState({});
    const [duration, setDuration] = useState(0);
    const [questions, setQuestions] = useState([]);
    const [activeQuestion, setActiveQuestion] = useState({});
    const [activeDISCQuestion, setActiveDISCQuestion] = useState({});
    const [candidateAnswer, setCandidateAnswer] = useState([]);
    const [loading, setLoading] = useState(false);
    const [complete, setComplete] = useState(false);
    const [selectedValue, setSelectedValue] = useState("");
    const { user: currentUser } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const Ref = useRef(null);
    const [timer, setTimer] = useState('00:00');
    const stateRef = useRef();
    stateRef.current = candidateAnswer;

    useOnPrintScreen();

    const getTimeRemaining = (e) => {
        const total = Date.parse(e) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60));
        return {
            total, minutes, seconds
        };
    }

    const startTimer = (e) => {
        let { total, minutes, seconds } = getTimeRemaining(e);

        if (total > 0) {

            // update the timer
            // check if less than 10 then we need to 
            // add '0' at the beginning of the variable
            setTimer(
                (minutes > 9 ? minutes : '0' + minutes) + ':'
                + (seconds > 9 ? seconds : '0' + seconds)
            )
        }

        if (total === 0) {
            handleSubmitAnswer();
        }
    }

    const str_pad_left = (string, pad, length) => {
        return (new Array(length + 1).join(pad) + string).slice(-length);
    };

    const clearTimer = (e) => {
        let finalTime = 0;

        if (duration) {
            const time = duration;
            const minutes = Math.floor(time / 60);
            const seconds = time - minutes * 60;

            finalTime = str_pad_left(minutes, '0', 2) + ':' + str_pad_left(seconds, '0', 2);
        }
        // If you adjust it you should also need to
        // adjust the Endtime formula we are about
        // to code next    
        setTimer(finalTime);

        // If you try to remove this line the 
        // updating of timer Variable will be
        // after 1000ms or 1sec
        if (Ref.current) clearInterval(Ref.current);
        const id = setInterval(() => {
            startTimer(e);
        }, 1000)
        Ref.current = id;
    }

    const getDeadTime = () => {
        let deadline = new Date();
        let time = 0;

        if (duration) {
            time = duration;
        }

        // This is where you need to adjust if 
        // you entend to add more time
        deadline.setSeconds(deadline.getSeconds() + time);
        return deadline;
    }

    const handleActiveQuestion = ({ key }) => {
        if (banksoaltype.name === "DISC") {
            setActiveQuestion(questions[key]);
            setActiveDISCQuestion(questions[key + 1]);
            setSelectedValue('');

            // set selected value
            let question_idx = candidateAnswer.findIndex(o => o.question_id === questions[key].id);
            if (question_idx >= 0) {
                let answer_id = candidateAnswer[question_idx].answer_id;
                let answer_value_idx = questions[key].answers.findIndex(o => o.id === answer_id);
                setSelectedValue(questions[key].answers[answer_value_idx].answer_value);
            }
        } else {
            setActiveQuestion(questions[key]);
        }
    }

    const handleCandidateAnswer = ({ key, answer_value }) => {
        let questionOptions = JSON.parse(activeQuestion.options);

        if (questionOptions !== null && questionOptions.disc_question_indicator === "MOST") {
            let idx = questions.findIndex(o => o.id === activeQuestion.id);
            let leastQuestionId = questions[idx + 1].id;
            let leastAnswerIdx = candidateAnswer.findIndex(o => o.question_id === leastQuestionId);
            if (leastAnswerIdx > -1) {
                candidateAnswer.splice(leastAnswerIdx, 1);
            }
        }

        let newAnswer = { exam_id: activeQuestion.exam_id, question_id: activeQuestion.id, answer_id: key, answer_value: answer_value };
        if (candidateAnswer && candidateAnswer.length > 0) {
            let found = 0;
            let updatedList = candidateAnswer.map(item => {
                if (item.question_id === activeQuestion.id) {
                    found = 1;
                    return { ...item, answer_id: key, answer_value: answer_value };
                }
                return item;
            });

            setCandidateAnswer(updatedList);

            if (!found) {
                setCandidateAnswer(prevState => ([
                    ...prevState, newAnswer
                ]));
            }
        } else {
            setCandidateAnswer([newAnswer]);
        }

        setSelectedValue(answer_value);

        setLoading(true);
        let answers = [{ exam_id: activeQuestion.exam_id, question_id: activeQuestion.id, answer_id: key }];
        ExamService.saveTempCandidateAnswer(params.id, answers).then()
            .finally(() => {
                setLoading(false);
            });
    }

    const handleCandidateDISCAnswer = ({ key, answer_value }) => {
        let newAnswer = { exam_id: activeDISCQuestion.exam_id, question_id: activeDISCQuestion.id, answer_id: key, answer_value: answer_value };
        if (candidateAnswer && candidateAnswer.length > 0) {
            let found = 0;
            let updatedList = candidateAnswer.map(item => {
                if (item.question_id === activeDISCQuestion.id) {
                    found = 1;
                    return { ...item, answer_id: key, answer_value: answer_value };
                }
                return item;
            });

            setCandidateAnswer(updatedList);

            if (!found) {
                setCandidateAnswer(prevState => ([
                    ...prevState, newAnswer
                ]));
            }
        } else {
            setCandidateAnswer([newAnswer]);
        }

        setLoading(true);
        let answers = [{ exam_id: activeDISCQuestion.exam_id, question_id: activeDISCQuestion.id, answer_id: key }];
        ExamService.saveTempCandidateAnswer(params.id, answers).then()
            .finally(() => {
                setLoading(false);
            });
    }

    const handleSubmitAnswer = () => {
        let answers = stateRef.current;
        setShowModal(false);
        setLoading(true);
        clearInterval(Ref.current);
        ExamService.saveCandidateAnswer(params.id, answers).then(
            (response) => {
                let { data: { next } } = response.data;
                if (next.module_id !== 0) {
                    navigate('/' + next.url + '/' + next.module_id);
                } else {
                    navigate('/' + next.url);
                }
            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                console.log(_content);

                if (error.response && error.response.status === 401) {
                    dispatch(logout());
                }
            }
        )
            .finally(() => {
                setLoading(false);
            });
    }

    const handlePrevQuestion = () => {
        if (activeQuestion) {
            let idx = questions.findIndex(o => o.id === activeQuestion.id);

            if (banksoaltype.name === "DISC") {
                setActiveQuestion(questions[idx - 2]);
                setActiveDISCQuestion(questions[idx - 1]);
                setSelectedValue('');

                // set selected value
                let question_idx = candidateAnswer.findIndex(o => o.question_id === questions[idx - 2].id);
                if (question_idx >= 0) {
                    let answer_id = candidateAnswer[question_idx].answer_id;
                    let answer_value_idx = questions[idx - 2].answers.findIndex(o => o.id === answer_id);
                    setSelectedValue(questions[idx - 2].answers[answer_value_idx].answer_value);
                }
            } else {
                setActiveQuestion(questions[idx - 1]);
            }

        }
    }

    const handleNextQuestion = () => {
        if (activeQuestion) {
            let idx = questions.findIndex(o => o.id === activeQuestion.id);

            if (banksoaltype.name === "DISC") {
                setActiveQuestion(questions[idx + 2]);
                setActiveDISCQuestion(questions[idx + 3]);
                setSelectedValue('');

                // set selected value
                let question_idx = candidateAnswer.findIndex(o => o.question_id === questions[idx + 2].id);
                if (question_idx >= 0) {
                    let answer_id = candidateAnswer[question_idx].answer_id;
                    let answer_value_idx = questions[idx + 2].answers.findIndex(o => o.id === answer_id);
                    setSelectedValue(questions[idx + 2].answers[answer_value_idx].answer_value);
                }
            } else {
                setActiveQuestion(questions[idx + 1]);
            }

        }
    }

    const isCandidateAnswer = (id) => {
        let x = candidateAnswer.find(({ answer_id }) => answer_id === id);
        if (x !== undefined) {
            return true;
        }
    }

    const isCandidateQuestion = (id) => {
        let x = candidateAnswer.find(({ question_id }) => question_id === id);
        if (x !== undefined) {
            return true;
        }
    }

    useEffect(() => {
        clearTimer(getDeadTime());
    }, [duration])

    useEffect(() => {

        window.onpopstate = e => {
            clearInterval(Ref.current);
        }

        setLoading(true);
        ExamService.getQuestions(params.id).then(
            (response) => {
                let { data: { module: { finish, name, duration, banksoal: { banksoaltype } }, questions, logs, temp_answers } } = response.data;
                setBankSoalType(banksoaltype);
                setTitle(name);
                setQuestions(questions);
                setActiveQuestion(questions[0]);
                setComplete(finish);
                setCandidateAnswer(temp_answers);

                if (banksoaltype.name === "DISC") {
                    let idx = questions.findIndex(o => o.question_number === questions[0].question_number);
                    setActiveDISCQuestion(questions[idx + 1]);

                    // set selected value
                    let question_idx = temp_answers.findIndex(o => o.question_id === questions[0].id);
                    if (question_idx >= 0) {
                        let answer_id = temp_answers[question_idx].answer_id;
                        let answer_value_idx = questions[0].answers.findIndex(o => o.id === answer_id);
                        setSelectedValue(questions[0].answers[answer_value_idx].answer_value);
                    }
                }

                let time = duration;
                let usedTime = 0;
                if (logs.length) {
                    let firstTime = new Date(logs[0].created_at);
                    let now = new Date(moment().format('YYYY-MM-DD H:mm:s'));

                    usedTime = (firstTime - now) / 1000;
                }

                time = time + usedTime;
                time = (time < 0) ? 0 : time;

                if (finish || time <= 0) {
                    handleSubmitAnswer();
                    time = 0;
                }

                setDuration(time);
            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                console.log(_content);

                if (error.response && error.response.status === 401) {
                    dispatch(logout());
                }
            }
        )
            .finally(() => {
                setLoading(false);
            })

    }, [params]);

    if (!currentUser) {
        return <Navigate to="/login" />;
    }

    return (
        <>
            {loading && <Loading />}
            <div className="container mx-auto">
                <div id="testContainerLeft">
                    <img className="img-responsive" alt="" id="logo-soechi" src="http://soechi.com/images/logo%20png.jpg" border="0" width="200" />
                </div>
                {banksoaltype &&
                    banksoaltype.name === "DISC" ? (
                    <>
                        <div id="testContainerRight">
                            <div id="testTimer"><div id="testTimerTimer">{timer}</div></div>
                            <div id="testQuestionNumSelector">
                                <div id="testQuestionNumSelectorCol1" className="grid grid-cols-4">
                                    {questions && questions.map((x, i) => {
                                        const curr = x.question_number;
                                        const next = (questions[i + 1]) ? questions[i + 1].question_number : 0;
                                        if (next === curr) {
                                            return (
                                                <div
                                                    onClick={() => handleActiveQuestion({ key: i })}
                                                    key={i}
                                                    className={`w-5 mr-2${x.id === activeQuestion.id ? ` questionNumPresent` : ``}${isCandidateQuestion(x.id) ? ` questionNumAnswered` : ` questionNumSelect`}`}>
                                                    {x.question_number}
                                                </div>
                                            )
                                        }
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="modul-title text-center">
                            <span className="text-2xl pr-14">{title.toUpperCase()}</span>
                        </div>

                        <div className="flex flex-row pr-14">
                            <div className="w-1/2 mr-5">
                                <div className="questionBlock h-40">
                                    {activeQuestion && ReactHtmlParser(activeQuestion.question_text)}
                                </div>
                                <div className="flex flex-col items-center">
                                    {
                                        !complete &&
                                        activeQuestion.answers &&
                                        activeQuestion.answers.length > 0 &&
                                        activeQuestion.answers.map((x, i) =>
                                            <button
                                                onClick={() => handleCandidateAnswer({ key: x.id, answer_value: x.answer_value })}
                                                key={x.id}
                                                value={x.answer_value}
                                                className={`w-full answerBlock ${isCandidateAnswer(x.id) ? `visited` : ``} mb-5`}
                                            >
                                                <div className="answerAlphabet">
                                                    {x.input_type === "image" ? (<span className="absolute mt-[55px]">{String.fromCharCode(i + 65)} .</span>) : (<span>{String.fromCharCode(i + 65)} .</span>)} {x.input_type === "image" ? (<img src={x.name} className="w-32 ml-7" />) : x.name}
                                                </div>
                                            </button>
                                        )
                                    }
                                </div>
                            </div>

                            <div className="w-1/2">
                                <div className="questionBlock h-40">
                                    {activeDISCQuestion && ReactHtmlParser(activeDISCQuestion.question_text)}
                                </div>
                                <div className="flex flex-col items-center">
                                    {
                                        activeDISCQuestion.answers &&
                                        activeDISCQuestion.answers.length > 0 &&
                                        activeDISCQuestion.answers.map((x, i) =>
                                            <button
                                                onClick={() => handleCandidateDISCAnswer({ key: x.id, answer_value: x.answer_value })}
                                                key={x.id}
                                                value={x.answer_value}
                                                disabled={selectedValue && selectedValue === x.answer_value ? true : false}
                                                className={`w-full answerBlock ${isCandidateAnswer(x.id) ? `visited` : ``} mb-5`}
                                            >
                                                <div className="answerAlphabet">
                                                    {x.input_type === "image" ? (<span className="absolute mt-[55px]">{String.fromCharCode(i + 65)} .</span>) : (<span>{String.fromCharCode(i + 65)} .</span>)} {x.input_type === "image" ? (<img src={x.name} className="w-32 ml-7" />) : x.name}
                                                </div>
                                            </button>
                                        )
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-row">
                            <div className="basis-2/6 mt-5 mb-5 pl-[2%]">
                                {activeDISCQuestion &&
                                    questions &&
                                    questions.findIndex(o => o.id === activeQuestion.id) !== 0 && (
                                        <button onClick={() => handlePrevQuestion()} className="block text-white bg-blue-500 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button">
                                            Prev
                                        </button>
                                    )}
                            </div>
                            <div className="basis-2/6 mt-5 mb-5 pr-[11%]">
                                {duration > 0 &&
                                    candidateAnswer.length > 1 &&
                                    candidateAnswer.length === questions.length && (
                                        <button onClick={() => setShowModal(true)} data-modal-target="popup-modal" data-modal-toggle="popup-modal" className="block text-white bg-green-500 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 float-right" type="button">
                                            Finish
                                        </button>
                                    )}
                            </div>
                            <div className="basis-2/6 mt-5 mb-5 pr-[2%]">
                                {activeDISCQuestion &&
                                    questions &&
                                    questions.findIndex(o => o.id === activeDISCQuestion.id) !== questions.length - 1 && (
                                        <button onClick={() => handleNextQuestion()} className="block float-right text-white bg-blue-500 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button">
                                            Next
                                        </button>
                                    )}
                            </div>
                        </div>

                    </>
                ) : (
                    <>
                        <div id="testContainerRight">
                            <div id="testTimer"><div id="testTimerTimer">{timer}</div></div>
                            <div id="testQuestionNumSelector">
                                <div id="testQuestionNumSelectorCol1" className="grid grid-cols-4">
                                    {questions.map((x, i) =>
                                        <div
                                            onClick={() => handleActiveQuestion({ key: i })}
                                            key={i}
                                            className={`w-5 mr-2${x.id === activeQuestion.id ? ` questionNumPresent` : ``}${isCandidateQuestion(x.id) ? ` questionNumAnswered` : ` questionNumSelect`}`}>
                                            {x.question_number}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div id="testContainerInner" className="pl-32">
                            {title && (
                                <div className="modul-title text-center">
                                    <h2 className="text-2xl">{title.toUpperCase()}</h2>
                                </div>
                            )}
                            <div className="questionBlock">
                                {activeQuestion.question_text && ReactHtmlParser(activeQuestion.question_text)}
                            </div>
                            <div className="flex flex-col items-center">
                                {
                                    !complete &&
                                    activeQuestion.answers &&
                                    activeQuestion.answers.length > 0 &&
                                    activeQuestion.answers.map((x, i) =>
                                        <div
                                            onClick={() => handleCandidateAnswer({ key: x.id })}
                                            key={x.id}
                                            className={`w-full answerBlock ${isCandidateAnswer(x.id) ? `visited` : ``} mb-5`}
                                        >
                                            <div className="answerAlphabet">
                                                {x.input_type === "image" ? (<span className="absolute mt-[55px]">{String.fromCharCode(i + 65)} .</span>) : (<span>{String.fromCharCode(i + 65)} .</span>)} {x.input_type === "image" ? (<img src={x.name} className="w-32 ml-7" />) : x.name}
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                            <div className="container">
                                <div className="flex flex-row justify-center">
                                    <div className="basis-1/3 mt-5">
                                        {activeQuestion &&
                                            questions &&
                                            questions.findIndex(o => o.id === activeQuestion.id) !== 0 && (
                                                <button onClick={() => handlePrevQuestion()} className="block text-white bg-blue-500 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button">
                                                    Prev
                                                </button>
                                            )}
                                    </div>
                                    <div className="basis-1/3 mt-5 pl-14">
                                        {duration > 0 &&
                                            candidateAnswer.length > 0 &&
                                            candidateAnswer.length === questions.length && (
                                                <button onClick={() => setShowModal(true)} data-modal-target="popup-modal" data-modal-toggle="popup-modal" className="block text-white bg-green-500 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button">
                                                    Finish
                                                </button>
                                            )}
                                    </div>
                                    <div className="basis-1/3 mt-5">
                                        {activeQuestion &&
                                            questions &&
                                            questions.findIndex(o => o.id === activeQuestion.id) !== questions.length - 1 && (
                                                <button onClick={() => handleNextQuestion()} className="block float-right text-white bg-blue-500 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button">
                                                    Next
                                                </button>
                                            )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
                {showModal ? (
                    <>
                        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                            <div className="relative w-full h-full max-w-md md:h-auto">
                                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                                    <button onClick={() => setShowModal(false)} type="button" className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white" data-modal-hide="popup-modal">
                                        <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                        <span className="sr-only">Close modal</span>
                                    </button>
                                    <div className="p-6 text-center">
                                        <svg aria-hidden="true" className="mx-auto mb-4 text-gray-400 w-14 h-14 dark:text-gray-200" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                                        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Are you sure you want to finish?</h3>
                                        <button onClick={() => handleSubmitAnswer()} data-modal-hide="popup-modal" type="button" className="text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2">
                                            Yes, I'm sure
                                        </button>
                                        <button onClick={() => setShowModal(false)} data-modal-hide="popup-modal" type="button" className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">No, cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                    </>
                ) : null}
            </div>
        </>
    )
}

export default Question;