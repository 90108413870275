import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import LogoSoechi from 'assets/images/logo soechi.png';
import ExamIllustration1 from 'assets/images/exam illustration 1.png';
import ExamIllustration2 from 'assets/images/exam illustration 2.png';
import ExamIllustration3 from 'assets/images/exam illustration 3.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faStopwatch, faClipboardList } from "@fortawesome/free-solid-svg-icons";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";

import ExamService from "services/exam.service";
import LoginService from "services/auth.service";
import { logout } from "redux/actions/auth";
import { Loading } from "components";
import { useRef } from "react";
import { useOnHoverOutside } from "context/useOnHoverOutside";
import { useOnPrintScreen } from "context/useOnKeyPress";
import Avatar from "react-avatar";
import authService from "services/auth.service";

const ExamImage = [
    ExamIllustration1,
    ExamIllustration2,
    ExamIllustration3
]

const getTotalMinutes = (x) => {
    let total = 0;
    let array = x.data;

    if (array.length) {
        for (let index = 0; index < array.length; index++) {
            const element = array[index];
            total += element.duration;
        }
    }

    return Math.floor(total / 60);
}

const Landing = () => {

    let navigate = useNavigate();
    const profileRef = useRef(null);
    const [content, setContent] = useState([]);
    const [profile, setProfile] = useState({});
    const [loading, setLoading] = useState(false);
    const [isMenuProfileOpen, setIsMenuProfileOpen] = useState(false);
    const { user: currentUser } = useSelector((state) => state.auth);
    const [swiper, setSwiper] = useState(Swiper);
    const [swiperIndex, setSwiperIndex] = useState(0);

    const dispatch = useDispatch();

    // Function to close dropdown
    const closeHoverMenu = () => {
        setIsMenuProfileOpen(false);
    };

    const handleLogout = () => {
        setLoading(true);
        authService.logout().then(
            (response) => {
                localStorage.removeItem("user");
                navigate('/');
            }
        ).finally(() => {
            setLoading(false);
        });
    }

    const toggleProfile = () => {
        setIsMenuProfileOpen((prevstate) => !prevstate);
    }

    useOnHoverOutside(profileRef, closeHoverMenu);
    useOnPrintScreen();

    useEffect(() => {
        setLoading(true);
        ExamService.getBankSoal().then(
            (response) => {
                let { data } = response.data;
                setContent(data);

                if (data.length === 0) {
                    navigate("/finish");
                }
            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                console.log(_content);

                if (error.response && error.response.status === 401) {
                    dispatch(logout());
                }
            }
        )
            .finally(() => {
                setLoading(false);
            })

        LoginService.profile().then(
            (response) => {
                let { data: { candidates } } = response.data;
                setProfile(candidates);
            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                console.log(_content);
            }
        )
            .finally(() => {
                setLoading(false);
            })

    }, []);

    if (!currentUser) {
        return <Navigate to="/login" />;
    }

    const userIcon = <FontAwesomeIcon icon={faUser} />;
    const stopwatchIcon = <FontAwesomeIcon icon={faStopwatch} color="#000" />;
    const clipboardIcon = <FontAwesomeIcon icon={faClipboardList} color="#000" />;

    return (
        <>
            {loading && <Loading />}
            <nav className="flex items-center justify-between flex-wrap bg-white pt-2 pb-2 pl-10">
                <div className="flex items-center flex-shrink-0 text-white mr-6">
                    <img src={LogoSoechi} className="w-1/4" />
                    <h2 className="text-[#285395] text-lg font-sans font-bold">PT Soechi Lines Tbk</h2>
                </div>
                <div className="w-full block lg:flex lg:items-center lg:w-auto">
                    <div className="rounded-3xl bg-[#dfe3ef] items-end float-right mr-10">
                        <a onClick={toggleProfile} href="#" className="inline-block font-sans text-sm px-4 py-2 text-black font-bold">{profile && profile.full_name} <i className="ml-1">{userIcon}</i></a>
                    </div>
                </div>
            </nav>
            {isMenuProfileOpen && (
                <div className="container absolute w-64 top-15 right-10 rounded-lg bg-black bg-opacity-80 text-white font-sans z-[2]">
                    <div className="flex flex-col pb-7">
                        <Avatar name={profile.full_name} color="#fff" fgColor="#000" size="80" round="50px" className="mt-3 ml-11 mb-6" />
                        <div className="text-center">
                            <span className="text-md">{profile.full_name}</span>
                        </div>
                        <div className="text-center mb-4">
                            <span className="text-xs">{profile.email_address}</span>
                        </div>
                        <hr className="border-2 mx-7"></hr>
                        <div className="pl-7 mb-1 mt-4">
                            <span className="text-xs">Position Apply : <span className="bg-white text-black font-bold px-1 rounded-lg">{profile.applied_position}</span></span>
                        </div>
                        <div className="pl-7 mb-1 mt-1">
                            <span className="text-xs">Last Education : <span className="bg-white text-black font-bold px-1 rounded-lg">{profile.education}</span></span>
                        </div>
                        <div className="pl-7 mb-1 mt-1">
                            <span className="text-xs">Gender : <span className="bg-white text-black font-bold px-1 rounded-lg">{profile.gender === "L" ? "Laki Laki" : "Perempuan"}</span></span>
                        </div>
                        <div className="pl-7 mb-1 mt-1">
                            <span className="text-xs">Phone Number : <span className="bg-white text-black font-bold px-1 rounded-lg">+{profile.dial_code}{profile.phone_number}</span></span>
                        </div>
                        <div className="text-center mt-4">
                            <button onClick={() => handleLogout()} className="text-xs bg-blue-600 py-1 px-4 rounded-xl">Logout</button>
                        </div>
                    </div>
                </div>
            )}
            <div className="container mx-auto">
                <Swiper
                    initialSlide={swiperIndex}
                    slidesPerView={1}
                    spaceBetween={30}
                    pagination={{ clickable: true }}
                    navigation={true}
                    modules={[Pagination, Navigation]}
                    className="mySwiper"
                    onSwiper={(swiper) => setSwiper(swiper)}
                    onSlideChange={(swiper) => setSwiperIndex(swiper.activeIndex)}
                >
                    {content &&
                        content.map((x, i) =>
                            <SwiperSlide key={i}>
                                <div className="flex items-center justify-between">
                                    <div className="basis-1/2">
                                        <div className="float-right">
                                            <h1 className="text-[#285395] font-bold font-sans text-2xl">{x.banksoal_name}</h1>
                                            <div className="text-black text-sm font-sans w-1/2 mt-2 mb-2">{x.banksoal_name} Test ini ingin mengetahui kemampuan peserta dalam test ini.</div>
                                            <Link
                                                to={{
                                                    pathname: `/intro/${x.id}`,
                                                    state: { id: x.id }
                                                }}
                                                className="inline-block font-sans text-sm px-6 pb-1 text-white font-bold bg-black rounded-lg">
                                                Start
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="basis-1/2">
                                        <img src={ExamImage[i]} className="w-1/2" />
                                    </div>
                                </div>
                            </SwiperSlide>
                        )}
                </Swiper>
                <div className="rounded-full h-80 w-80 bg-[#285395] absolute mt-[-10px] ml-[-80px] z-[-1]"></div>
                <div className="basis-full mt-5">
                    <div className="flex flex-row pl-14 pr-14">
                        {content &&
                            content.map((x, i) =>
                                <div onClick={() => swiper.slideTo(i)} key={i} className={`md:w-[350px] pt-4 pb-4 rounded-lg bg-[#BDBDBD] text-center mb-5 mr-10 cursor-pointer ${swiperIndex === i ? 'shadow-[0_10px_20px_#285395]' : ''}`}>
                                    <h1 className="text-1xl font-bold text-white font-sans mb-3">{x.banksoal_name}</h1>
                                    <div className="text-black px-5 lg:h-[100px]">
                                        {x.banksoal_name} Test ini ingin mengetahui kemampuan peserta dalam test ini.
                                    </div>
                                    <div className="flex flex-row mt-2 justify-center">
                                        <i className="mr-2">{stopwatchIcon}</i><span className="text-[#285395]">{getTotalMinutes(x)} Minutes</span>
                                        <i className="mr-2 ml-5">{clipboardIcon}</i><span className="text-[#285395]">{x.total_soal} Questions</span>
                                    </div>
                                </div>
                            )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Landing;